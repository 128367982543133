/* eslint-disable react/no-array-index-key */
import {
  forwardRef,
  Fragment,
} from 'react';
import { Typography } from '@mui/material';

function TagLeave(props, ref) {
  const {
    name,
    leaveTypographyProps,
    ...otherProps
  } = props;

  return (name ?? '').split(' ').map((word, index) => (
    <Fragment key={index}>
      <Typography
        ref={ref}
        sx={{ whiteSpace: 'nowrap' }}
        variant={leaveTypographyProps?.variant ?? 'body2'}
        {...leaveTypographyProps}
        {...otherProps}
      >
        {word}
      </Typography>
      <Typography variant={leaveTypographyProps?.variant ?? 'body2'}>
        &nbsp;
      </Typography>
    </Fragment>
  ));
}

const TagLeaveWithRef = forwardRef(TagLeave);
export default TagLeaveWithRef;
