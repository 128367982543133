import { PROJECT_TAG_REFERENCES } from '@modules/projects/config';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';

export function buildTree(tags, selectedTags, i18n) {
  const tree = [];
  const allTags = flatten(tags);

  tags.forEach((rootTag) => {
    if (!hasSelectedChildren(
      rootTag.attributes.child_items.data,
      selectedTags,
    )) {
      return;
    }

    const group = PROJECT_TAG_REFERENCES
      .find((x) => x.id === rootTag.attributes.referenceId)?.group ?? 0;
    const rootTagMapped = {
      id: rootTag.id,
      index: rootTag.attributes.index + (group * 100),
      name: rootTag.attributes[`name_${i18n.language}`],
      children: [],
    };
    tree.push(rootTagMapped);

    rootTag.attributes.child_items.data.forEach((childTag) => {
      buildTreeChildren(
        allTags,
        rootTagMapped,
        childTag,
        [childTag.id],
        selectedTags,
        i18n,
        Boolean(rootTag.attributes.isAbbreviationUsedAsLabel),
      );
    });
  });

  return orderBy(tree, ['index']);
}

export function buildClusters(tags, selectedTags, i18n) {
  return tags.map((rootTag) => ({
    id: rootTag.id,
    name: rootTag.attributes[`name_${i18n.language}`],
    items: rootTag.attributes.child_items.data.map((childTag) => ({
      id: childTag.id,
      name: childTag.attributes[`name_${i18n.language}`],
      color: childTag.attributes.color,
      isSelected: selectedTags.includes(childTag.id) || hasSelectedChildren(
        childTag.attributes.child_items.data,
        selectedTags,
      ),
    })),
  }));
}

function buildTreeChildren(
  tags,
  rootTag,
  childTag,
  parents,
  selectedTags,
  i18n,
  isAbbreviationUsedAsLabel,
) {
  if (selectedTags.includes(childTag.id)) {
    const displayTags = parents.map((id) => {
      const tag = tags.find((tag2) => tag2.id === id);

      return ({
        id: tag.id,
        name: isAbbreviationUsedAsLabel
          ? get(tag, `attributes.tag_item_abbreviations.data[0].attributes.name_${i18n.language}`)
          || tag.attributes[`name_${i18n.language}`]
          : tag.attributes[`name_${i18n.language}`],
        abbreviation: get(tag, `attributes.tag_item_abbreviations.data[0].attributes.name_${i18n.language}`)
          || tag.attributes[`name_${i18n.language}`],
        color: tag.attributes.color,
      });
    });
    rootTag.children.push(displayTags);
  }

  childTag.attributes.child_items.data.forEach((childTag2) => {
    buildTreeChildren(
      tags,
      rootTag,
      childTag2,
      [...parents, childTag2.id],
      selectedTags,
      i18n,
      isAbbreviationUsedAsLabel,
    );
  });
}

function hasSelectedChildren(items, selectedTags) {
  return items.some((item) => {
    if (selectedTags.includes(item.id)) {
      return true;
    }

    return hasSelectedChildren(item.attributes.child_items.data, selectedTags);
  });
}

function flatten(ary) {
  let ret = [];

  for (let i = 0; i < ary.length; i += 1) {
    ret.push(ary[i]);

    if (Array.isArray(ary[i].attributes.child_items.data)) {
      ret = ret.concat(flatten(ary[i].attributes.child_items.data));
    }
  }

  return ret;
}
